<template>
  <div class="container-cental">
    <h1 class="mt-5">{{ $t("messages.listoOfAgendas").toUpperCase() }}</h1>
    <div class="container-actions-top-table">
      <div class="container-searcher">
        <b-input-group size="sm" class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="search"
            :placeholder="$t('messages.search')"
            v-model.trim="textSearch"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <div class="container-table">
      <b-table
        striped
        :stacked="windowWidth < 600"
        hover
        :items="itemsFiltered"
        :fields="fields"
        :busy="loadingData"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="textSearch.toLowerCase()"
      >
        <template #cell(fecha)="data">
          <span>{{
            `${data.item.dia}-${data.item.mes}-${data.item.anio}`
          }}</span>
        </template>
        <template #cell(hora)="data">
          <span>{{ secondsToHM(data.item.hora) }}</span>
        </template>
        <template #cell(estado)="data">
          <b-icon
            :icon="data.item.estadoIcon.icon"
            :variant="data.item.estadoIcon.variant"
            scale="1.5"
            style="margin: 0px 10px"
            v-b-tooltip.hover
            :title="data.item.estadoIcon.title"
          ></b-icon>
        </template>
        <template #cell(actions)="data">
          <b-icon
            icon="eye"
            variant="warning"
            @click="verDetalle({ ...data.item })"
            scale="1.5"
            style="margin: 0px 10px; cursor: pointer"
            v-b-tooltip.hover
            :title="$t('messages.watchDetail')"
          ></b-icon>
          <b-icon
            v-if="data.item.estado === 1"
            icon="x-circle"
            variant="danger"
            @click="cancelItem({ ...data.item })"
            scale="1.5"
            style="margin: 0px 10px; cursor: pointer"
            v-b-tooltip.hover
            :title="$t('messages.cancelAppointment')"
          ></b-icon>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px"
              >{{ $t("messages.loadigData") }}...</strong
            >
          </div>
        </template>
      </b-table>
    </div>
    <div class="container-paginator">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <span style="margin-left: 15px">{{ $t("messages.itemsByPage") }}</span>
      <b-form-select
        v-model="perPage"
        :options="optionsPerPage"
        style="width: 80px; margin-left: 10px"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import CalendarioService from "@/services/calendario.service";
import EventBus from "@/services/eventBus.js";
import { Helpers } from "@/mixins/Helpers";
export default {
  name: "AgendamientosListClient",
  mixins: [Helpers],
  data() {
    return {
      calendarioService: null,
      sortBy: "apellidos_usuario",
      from: "agendamientoslistcliente",
      items: [],
      itemsFiltered: [],
      windowWidth: window.innerWidth,
      loadingData: false,
      perPage: 10,
      currentPage: 1,
      optionsPerPage: [5, 10, 25, 50, 100],
      textSearch: "",
      itemSeleted: null,
      sortDesc: false,
    };
  },
  created() {
    this.calendarioService = new CalendarioService();
  },
  mounted() {
    //go to  top
    window.scrollTo(0, 0);
    this.getItemsForList();
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
    EventBus.$on("RESPONSE_MODAL_QUESTION", (payLoad) => {
      if (payLoad.from == this.from) {
        switch (payLoad.action) {
          case "cancel":
            this.ejecuteCancel();
            break;
          default:
            break;
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    cancelItem(item) {
      this.itemSeleted = { ...item };
      EventBus.$emit("SHOW_MODAL_QUESTION", {
        titleModal: this.$t("messages.cancelAppointment"),
        messageModal: this.$t("messages.cancelAppointmentQuestion"),
        action: "cancel",
        from: this.from,
      });
    },
    ejecuteCancel() {
      if (!this.itemSeleted) return false;
      //sustituir el servicio
      this.loadingData = true;
      this.calendarioService
        .cancelarAgendamientoById({ id_sesion: this.itemSeleted.id })
        .then((result) => {
          if (result.data.status == 200) {
            //search and change estado to 4
            let index = this.items.findIndex((item) => {
              return item.id == this.itemSeleted.id;
            });
            this.items[index].estado = 4;

            let indexFiltered = this.itemsFiltered.findIndex((item) => {
              return item.id == this.itemSeleted.id;
            });

            this.itemsFiltered[indexFiltered].estado = 4;
            this.itemsFiltered[indexFiltered].estadoIcon =
              this.getObjectEstado(4);

            this.itemSeleted = null;

            EventBus.$emit("MOSTRAR_TOAST", {
              title: "",
              variant: "success",
              message: this.$t("messages.appointmentCanceled"),
            });
          }
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: this.$t("messages.appointmentCanceledError"),
          });
        });
    },
    getObjectEstado(estado) {
      /* 1 - propuesta
      2 - aceptada
      3 - rechazada
      4 - cancelada
      5 - finalizada */
      switch (estado) {
        case 1:
          return {
            icon: "check-circle",
            variant: "warning",
            title: this.$t("messages.proposed"),
          };
        case 2:
          return {
            icon: "check-circle-fill",
            variant: "success",
            title: this.$t("messages.accepted"),
          };
        case 3:
          return {
            icon: "calendar2-x",
            variant: "danger",
            title: this.$t("messages.rejected"),
          };
        case 4:
          return {
            icon: "trash",
            variant: "danger",
            title: this.$t("messages.canceled"),
          };
        case 5:
          return {
            icon: "trophy",
            variant: "success",
            title: this.$t("messages.finished"),
          };
        default:
          return { icon: "", variant: "", title: "" };
      }
    },
    getItemsForList() {
      this.loadingData = true;
      this.calendarioService
        .getAgendamientosByIdUsuarioAll()
        .then((result) => {
          this.items = [...result.data.data];
          this.items = this.items.map((item) => {
            item.estadoIcon = this.getObjectEstado(item.estado);
            return item;
          });
          this.itemsFiltered = [...result.data.data];
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: $t("messages.errorRecoveringData"),
          });
        });
    },
    verDetalle(item) {
      this.$router.push({
        name: "DetalleAgendamientoCliente",
        params: { id: item.id },
      });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.$t("messages.id") },
        { key: "estado", sortable: true, label: this.$t("messages.status") },
        { key: "fecha", sortable: true, label: this.$t("messages.date") },
        { key: "hora", sortable: true, label: this.$t("messages.time") },
        {
          key: "zona_horaria",
          sortable: true,
          label: this.$t("messages.timeZone"),
        },
        {
          key: "nombre_sesionista",
          sortable: true,
          label: this.$t("messages.nameChanneler"),
        },
        {
          key: "apellido_sesionista",
          sortable: true,
          label: this.$t("messages.lastNameChanneler"),
        },
        {
          key: "email_sesionista",
          sortable: true,
          label: this.$t("messages.channelerEmail"),
        },
        {
          key: "sitio_de_encuentro",
          sortable: true,
          label: this.$t("messages.meetingPlace"),
        },
        { key: "actions", sortable: false, label: this.$t("messages.actions") },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
}
.container-table {
  width: 100%;
  min-height: 300px;
  padding-bottom: 20px;
}
.container-paginator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding-bottom: 20px;
}
.container-actions-top-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
}
@media (max-width: 470px) {
  .container-paginator {
    flex-direction: column;
    align-items: center;
  }
}
</style>
